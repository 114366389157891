.tray-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
  border: 1px solid transparent;

  // Accessibility focus styles
  &:focus {
    outline: none;
    border-color: #5a43e9;
    box-shadow: 0 0 0 2px rgba(90, 67, 233, 0.2);
  }

  // Hover state
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fafafa;

    .arrow-icon {
      transform: translateX(4px);
      opacity: 1;
    }
  }

  // Active state
  &:active {
    transform: translateY(0);
    background-color: #f5f5f5;
  }

  .tray-data_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 0;
    position: relative;

    .tray-icon {
      width: 40px;
      height: 40px;
      object-fit: contain;
      flex-shrink: 0;
    }

    .arrow-icon {
      color: #5a43e9;
      font-size: 20px;
      opacity: 0.5;
      margin-left: auto;
      align-self: center;
      transition: all 0.2s ease-in-out;
    }

    .tray-data {
      flex: 1;

      .tray-data_name {
        font-size: 17px;
        font-weight: 500;
        color: #11142d;
        margin-bottom: 8px;
      }

      [data-field='Type'] {
        .field-value {
          text-transform: capitalize;
        }
      }
    }
  }
}

// Optional: Add a subtle "Click to view details" hint
.tray-component::after {
  content: 'Click to view details';
  position: absolute;
  bottom: 8px;
  right: 16px;
  font-size: 12px;
  color: #666;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.tray-component:hover::after {
  opacity: 1;
}
