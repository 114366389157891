.landing-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 151px;
  text-align: center;

  &.is-mobile {
    gap: 8px;

    .icon {
      height: 110px;
      width: 110px;
      max-width: 110px;
      max-height: 120px;

      background: var(--grayscale-lightest);
      padding: 10px;
      border-radius: 10px;

      .landing-icon-img {
        width: 100%;
        height: auto;
        max-height: 60px;
      }

      .landing-icon-img.explanted {
        max-width: 35px;
        height: auto;
        max-height: 80px;
      }
    }
  }

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 140px;
    width: 140px;
    max-width: 140px;
    max-height: 160px;

    background: var(--grayscale-lightest);
    padding: 10px;
    border-radius: 10px;

    .landing-icon-img {
      width: 100%;
      height: auto;
      max-height: 80px;
    }

    .landing-icon-img.explanted {
      max-width: 40px;
      height: auto;
      max-height: 100px;
    }

    &:hover {
      cursor: pointer;
      background: var(--primary-lightest);
      transition: all 0.3s;
    }
  }
}
