.main-icon-landing_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  overflow: auto;

  .icons-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    max-width: 510px;
    flex-wrap: wrap;
    gap: 25px;
    padding-bottom: 70px;

    &.two-columns {
      max-width: 500px;
    }
  }

  &.is-mobile {
    .icons-container {
      margin-top: 10px;
      justify-content: center;
      align-items: flex-start;
      max-width: fit-content;
      gap: 8px;
    }
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
