.assigned-digital-trays-container {
  width: 100%;
  padding: 16px;
  padding-bottom: 100px;

  .header-button {
    margin-bottom: 16px;
  }

  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .trays-content {
    margin-top: 16px;

    .tray-accordion {
      margin-bottom: 16px;
      border-radius: 4px;

      .accordion-summary {
        background-color: white;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        .summary-content {
          display: flex;
          align-items: center;
          width: 100%;
          padding-right: 8px;

          .tray-info {
            .tray-title {
              font-weight: 500;
            }

            .tray-barcode {
              color: rgba(0, 0, 0, 0.6);
            }
          }

          .tray-count {
            margin-left: auto;
            background-color: #5a43e9;
            color: white;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 48px;
          }
        }
      }

      .accordion-details {
        background-color: #f5f5f5;
        padding: 16px;
      }
    }
  }
}
