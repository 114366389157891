.tray-procedure-edit-mode-organism_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  overflow: hidden;
  position: relative;

  .toggle-button {
    padding: 8px 16px;
    background-color: #6200ea ;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    width: auto;
    margin: 0;
  }

  button {
    background-color: var(--primary);
    border: none;
    color: white;
    border-radius: 50%;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border: 2px solid var(--primary-light);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;

    &:hover {
      background-color: var(--primary-hover);
    }

    &:active {
      background-color: var(--primary);
    }

    &:focus {
      outline: none;
    }
  }

  .back-button {
    position: absolute;
    top: 30px;
    right: 10px;
    transform: translate(-10px, 0px);
    rotate: 90deg;
    padding: 5px;
  }

  .joystick-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 30;

    > div {
      display: flex;
      justify-content: space-between;
      width: 120px;
    }
  }

  .tray-map_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100vh - 130px);
    min-width: calc(100vh - 130px);
    margin-top: 130px;
    height: 100% !important;
    rotate: 90deg;
  }

  .zoom-controls {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: 20px;

    button {
      rotate: 90deg;
    }
  }

  .confirmation-popover {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 90%;
    max-width: 400px;

    .confirmation-popover-content {
      text-align: center;

      p {
        margin: 0 0 16px;
        color: var(--text-primary);
        font-size: 16px;
      }

      .confirmation-actions {
        display: flex;
        gap: 12px;
        justify-content: center;

        button {
          border-radius: 4px;
          padding: 8px 16px;
          font-size: 14px;
          width: auto;
          height: auto;
          min-width: 100px;

          &.confirm-button {
            background-color: var(--primary);
          }

          &.cancel-button {
            background-color: var(--error);
          }
        }
      }
    }
  }

  .selection-actions {
    position: absolute;
    bottom: 50%;
    left: 40px;
    transform: translate(-50%, 40px);
    z-index: 30;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 20px;

    .toggle-button {
      width: auto;
      min-width: 200px;
      border-radius: 4px;
      rotate: 90deg;
    }
  }

  @media (orientation: landscape) {
    flex-direction: row;
    padding: 20px;

    .back-button {
      top: 20px;
      left: 20px;
      transform: rotate(-90deg);
    }

    .joystick-container {
      top: auto;
      bottom: 20px;
      left: 20px;
      transform: translate(0, 0);
      rotate: -90deg;

      button {
        rotate: 0deg;
      }
    }

    .tray-map_wrapper {
      width: calc(100% - 140px);
      max-width: calc(100% - 140px);
      height: 100% !important;
      max-height: 100% !important;
      rotate: 0deg;
      margin-left: 140px;
      margin-top: 0px;
    }

    .zoom-controls {
      flex-direction: column;
      bottom: 20px;
      right: 20px;
      width: auto;

      button {
        rotate: 0deg;
      }
    }

    .selection-actions {
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);

      .toggle-button {
        rotate: 0deg;
      }
    }
  }
}

